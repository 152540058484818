<template>
  <div>
    <a-card :title="$t('库位平面图')" :loading="queryLoading">
      <a-row :gutter="[12, 12]">
        <a-col :span="24" style="width: 200px">
          <a-select v-model="warehouse" :placeholder="$t('仓库')" style="width: 100%" @change="queryData">
            <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 200px">
          <a-select v-model="reservoirArea" :placeholder="$t('库区')" allowClear style="width: 100%">
            <a-select-option v-for="item in reservoirAreaItems" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 240px">
          <a-input v-model="searchValue" :placeholder="$t('货品编号, 名称, 条码')" allowClear />
        </a-col>

        <a-col :span="24">
          <a-space :size="24">
            <a-badge color="#52c41a" :text="$t('查询货品')" />
            <a-badge color="#108ee9" :text="$t('有货库位')" />
            <a-badge color="#faad14" :text="$t('无货库位')" />
            <a-badge color="#d9d9d9" :text="$t('无库位')" />
          </a-space>
        </a-col>
      </a-row>

      <a-row>
        <a-col v-for="item in dataItems" :key="item.reservoir_area">
          <div v-if="!reservoirArea || reservoirArea == item.reservoir_area">
            <a-divider orientation="left">{{ item.reservoir_area_name }}</a-divider>
            <div v-for="(locationItems, areaNumber) in item.area_dict" :key="areaNumber">
              <div style="display: grid; grid-template-columns: 80px auto; margin: 16px 0">
                <div style="display: flex; align-items: center; justify-content: center; padding: 10px">
                  {{ areaNumber }} 号货架/区域
                </div>
                <div style="overflow-x: auto">
                  <div v-for="row in item.reservoir_area_max_row" :key="row" style="display: flex">
                    <div v-for="col in item.reservoir_area_max_col" :key="col">
                      <LocationCell :item="getLocationItem(locationItems, row, col)" :searchValue="searchValue" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { warehouseOption } from "@/api/option";
import { planDatas } from "@/api/data";

export default {
  components: {
    LocationCell: () => import("./LocationCell"),
  },
  data() {
    return {
      warehouse: undefined,
      warehouseItems: [],
      reservoirArea: undefined,
      reservoirAreaItems: [],
      queryLoading: false,
      dataItems: [],
      locationDict: {},
      searchValue: "",
    };
  },
  computed: {
    defaultWarehouse() {
      return this.$store.state.user.defaultWarehouse;
    },
  },
  methods: {
    queryData() {
      this.queryLoading = true;
      planDatas({ warehouse: this.warehouse })
        .then((data) => {
          this.dataItems = data;
          const locationDict = {};
          const reservoirAreaItems = [];

          for (const item of this.dataItems) {
            reservoirAreaItems.push({ id: item.reservoir_area, name: item.reservoir_area_name });
            for (const areaNumber in item["area_dict"]) {
              for (const locationItem of item["area_dict"][areaNumber]) {
                locationDict[`${areaNumber}-${locationItem.row}-${locationItem.col}`] = locationItem;
              }
            }
          }

          this.locationDict = locationDict;
          this.reservoirAreaItems = reservoirAreaItems;
        })
        .finally(() => (this.queryLoading = false));
    },
    getLocationItem(locationItems, row, col) {
      for (const item of locationItems) {
        if (item.row === row && item.col === col) {
          return item;
        }
      }
      return {};
    },
  },
  mounted() {
    warehouseOption({ page_size: 999999 }).then((data) => {
      this.warehouseItems = data.results;

      if (this.defaultWarehouse) {
        this.warehouse = this.defaultWarehouse;
        this.queryData();
      } else if (this.warehouseItems.length > 0) {
        this.warehouse = this.warehouseItems[0]["id"];
        this.queryData();
      }
    });
  },
};
</script>

<style scoped></style>
